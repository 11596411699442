import React, { useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Spinner } from '../Spinner'
import { useForm } from 'react-hook-form'

const RequestDemoForm = ({ open, popClose }) => {

    const [loading, setLoading] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        console.log(data);
    };


    return (
        <>
            <Modal
                show={open}
                onHide={popClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className='requestFormModal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter text-center">
                        Request for Demo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <Row>
                            <Col>
                                <Form className='requestForm' onSubmit={handleSubmit(onSubmit)}>
                                    <div className="requestFormInput_container">
                                        <Form.Group className="requestFormInput mb-3" controlId="formBasicEmail">
                                            <Form.Label>Name :</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Name"
                                                {...register('name', { required: 'Name is required' })}
                                            />
                                            {errors.name && <p className='formError_text'>{errors.name.message}</p>}
                                        </Form.Group>
                                        <Form.Group className="requestFormInput mb-3" controlId="formBasicEmail">
                                            <Form.Label>Company Name :</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Company Name"
                                                {...register('companyName', { required: 'Company Name is required' })}
                                            />
                                            {errors.companyName && <p className='formError_text'>{errors.companyName.message}</p>}
                                        </Form.Group>
                                        <Form.Group className="requestFormInput mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email :</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email"
                                                {...register('email', { required: 'Email is required', validate: (value) => { if (!/^\S+@\S+\.\S+$/.test(value)) { return 'Invalid email address'; } }, })}
                                            />
                                            {errors.email && <p className='formError_text'>{errors.email.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="requestFormInput mb-3" controlId="formBasicPassword">
                                            <Form.Label>Phone :</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Phone Number"
                                                {...register('phone', { required: 'Phone number is required', minLength: { value: 7, message: 'Phone number must be at least 7 digits', }, maxLength: { value: 15, message: 'Phone number cannot exceed 15 digits', }, pattern: { value: /^\d{7,15}$/, message: 'Invalid phone number', }, })}
                                            />
                                            {errors.phone && <p className='formError_text'>{errors.phone.message}</p>}
                                        </Form.Group>
                                        <Form.Group className="requestFormInput mb-3 w-100" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control as="textarea" rows={6} {...register('message', { required: 'Message is required' })} />
                                            {errors.message && <p className='formError_text'>{errors.message.message}</p>}
                                        </Form.Group>
                                    </div>

                                    <div className='endDiv mt-3'>
                                        <button className="secondaryBtn mx-3" style={{ minWidth: '150px' }} onClick={popClose}>Cancel</button>
                                        <button className="primaryBtn" style={{ minWidth: '150px', backgroundColor: '#ff0303' }} type="submit">
                                            {loading ? <Spinner /> : 'Submit'}
                                        </button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RequestDemoForm