import React from 'react'
import './Footer.css'
import { CCircle, EnvelopeFill, Facebook, Instagram, Linkedin, TwitterX } from 'react-bootstrap-icons'

const Footer = () => {
  return (
    <>
      <div className='footerDiv'>
        <div className="socialMedia">
          <Facebook />
          <TwitterX />
          <Linkedin />
          <Instagram />
          <EnvelopeFill />
        </div>
        <div className='copyright mt-5'>
          Copyright <CCircle /> 2024 OneXR Showroom
        </div>
      </div>
    </>
  )
}

export default Footer