import React, { useEffect } from 'react'
import './Topbar.css'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { ONEXR_BRAND_LOGO } from '../assets/allAssets';
import RequestDemoForm from '../Homepage/RequestDemoForm';
import { Offcanvas } from 'react-bootstrap';
import { CCircle, List } from 'react-bootstrap-icons';

const Topbar = (props) => {


  const [requestFormShow, setRequestFormShow] = React.useState(false);
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState('heroSection');
  let scrollTimeout = null;

  const modalClose = () => {
    setRequestFormShow(false)
  }

  const handleScroll = () => {
    const sections = document.querySelectorAll('section');
    let currentSection = '';

    sections.forEach((section) => {
      const sectionTop = section.getBoundingClientRect().top;
      const sectionHeight = section.clientHeight;

      // Checking if the section is in view
      if (sectionTop <= window.innerHeight / 5 && sectionTop + sectionHeight >= window.innerHeight / 2) {
        currentSection = section.getAttribute('id');
      }
    });

    setActiveSection(currentSection);

    if (window.scrollY >= 800) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };


  const handleNavClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      // setActiveSection(sectionId);
      setTimeout(() => {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // ----------- offcanvas navmenu ---------
  const [showOffcanvas, setShowOffcanvas] = React.useState(false);

  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcancasShow = () => setShowOffcanvas(true);



  return (
    <>
      <Navbar collapseOnSelect expand="lg" className={`bg-body-tertiary navSection ${isScrolled ? 'scrolled' : ''}`}>
        <Container>
          <Navbar.Brand href="#home">
            <img className='brandLogo' src={ONEXR_BRAND_LOGO} alt="BrandLogo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => handleNavClick('heroSection')} className={activeSection === 'heroSection' ? 'active' : ''}>Home</Nav.Link>
              <Nav.Link onClick={() => handleNavClick('walkThroughSection')} className={activeSection === 'walkThroughSection' ? 'active' : ''}>Walk-Through</Nav.Link>
              <Nav.Link onClick={() => handleNavClick('featureSection')} className={activeSection === 'featureSection' ? 'active' : ''}>Features</Nav.Link>
              <Nav.Link onClick={() => handleNavClick('portfolioSection')} className={activeSection === 'portfolioSection' ? 'active' : ''}>Portfolio</Nav.Link>
              <Nav.Link onClick={() => handleNavClick('sevices')} className={activeSection === 'sevices' ? 'active' : ''} >Services</Nav.Link>
              <Nav.Link onClick={() => handleNavClick('blogSection')} className={activeSection === 'blogSection' ? 'active' : ''}>Blog</Nav.Link>
              <Nav.Link onClick={() => handleNavClick('pricingSection')} className={activeSection === 'pricingSection' ? 'active' : ''}>Pricing</Nav.Link>
              <Nav.Link onClick={() => handleNavClick('contactSection')} className={activeSection === 'contactSection' ? 'active' : ''}>Contact</Nav.Link>
              <button className='requestDemoBtn' onClick={() => setRequestFormShow(true)}>Request for Demo</button>
              <Nav.Link className='sidebarMenuBtn' onClick={handleOffcancasShow}><List /></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      <Offcanvas show={showOffcanvas} onHide={handleOffcanvasClose} placement='end' className='offcanvasContainer'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='offcanvasNavMenu'>
          <Nav.Link onClick={() => handleNavClick('heroSection')} className={activeSection === 'heroSection' ? 'active' : ''} >Home</Nav.Link>
          <Nav.Link onClick={() => handleNavClick('walkThroughSection')} className={activeSection === 'walkThroughSection' ? 'active' : ''} >Walk-Through</Nav.Link>
          <Nav.Link onClick={() => handleNavClick('featureSection')} className={activeSection === 'featureSection' ? 'active' : ''} >Features</Nav.Link>
          <Nav.Link onClick={() => handleNavClick('portfolioSection')} className={activeSection === 'portfolioSection' ? 'active' : ''} >Portfolio</Nav.Link>
          <Nav.Link onClick={() => handleNavClick('sevices')} className={activeSection === 'sevices' ? 'active' : ''} >Services</Nav.Link>
          <Nav.Link onClick={() => handleNavClick('blogSection')} className={activeSection === 'blogSection' ? 'active' : ''} >Blog</Nav.Link>
          <Nav.Link onClick={() => handleNavClick('pricingSection')} className={activeSection === 'pricingSection' ? 'active' : ''} >Pricing</Nav.Link>
          <Nav.Link onClick={() => handleNavClick('contactSection')} className={activeSection === 'contactSection' ? 'active' : ''} >Contact</Nav.Link>
          <button className='requestDemoBtn' onClick={() => setRequestFormShow(true)} >Request for Demo</button>

          <div className='text-center w-100' style={{ position: 'absolute', bottom: '5%' }}>
            <div className='copyright mt-5 w-100'>
              Copyright <CCircle /> 2024 OneXR Showroom
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>




      <RequestDemoForm open={requestFormShow} popClose={modalClose} />
    </>
  )
}

export default Topbar