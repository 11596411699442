


const ORIGIN = window.location.origin;

export const ONEXR_BRAND_LOGO = `${ORIGIN}/assets/images/logoONEXR.svg`
export const ONEXR_BG_VIDEO = `${ORIGIN}/assets/video/preview.mp4`
export const HOME_FEATURE_IMG = `${ORIGIN}/assets/images/solution-2.png`
export const WALK_THROUGH_CONTENT_IMG = `${ORIGIN}/assets/images/products-2.png`

export const WALK_THROUGH_CONTENT_IMG1 = `${ORIGIN}/assets/images/brandRecall2.png`
export const WALK_THROUGH_CONTENT_IMG2 = `${ORIGIN}/assets/images/TechnicallyAdvanced2.png`
export const WALK_THROUGH_CONTENT_IMG3= `${ORIGIN}/assets/images/WorthySupplier.png`

export const VECTOR_IMAGE1 = `${ORIGIN}/assets/images/CADto3D_2.png`
export const VECTOR_IMAGE2 = `${ORIGIN}/assets/images/TransformWebsite.png`
export const VECTOR_IMAGE3 = `${ORIGIN}/assets/images/vector3.svg`

export const PORTFOLIO_IMAGE1 = `${ORIGIN}/assets/images/portfolio1.jpg`
export const PORTFOLIO_IMAGE2 = `${ORIGIN}/assets/images/portfolio2.jpg`
export const PORTFOLIO_IMAGE3 = `${ORIGIN}/assets/images/portfolio3.jpg`

export const INITIAL_PLANNING = `${ORIGIN}/assets/images/initialConsultation2.webp`
export const DESIGN_DEVELOPMENT = `${ORIGIN}/assets/images/design_development.svg`
export const LAUNCH_SUPPORT = `${ORIGIN}/assets/images/launch_support.svg`


export const FEATURE_SLIDE1 = `${ORIGIN}/assets/images/WhyMatter.jpg`
export const FEATURE_SLIDE2 = `${ORIGIN}/assets/images/OurValueToYou.jpg`


export const FEATURE2_SLIDE2 = `${ORIGIN}/assets/images/feature2_slide2.png`
export const FUNNEL_VIDEO = `${ORIGIN}/assets/video/funnel_video.mp4`
