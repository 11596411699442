
import axios from 'axios';
import React, { useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Spinner } from '../Spinner';

const SelectPlanForm = ({ open, popClose, base, plan }) => {

    const [loader, setLoader] = useState(false)

    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    const handleFormSubmit = async (data) => {


        try {
            setLoader(true)
            const formData = new FormData()
            formData.append('name', data.name)
            formData.append('email', data.email)
            formData.append('contact', data.phone)
            formData.append('company', data.companyName)
            formData.append('plan', `${base} - ${plan}`)
            let response = await axios.post(`${process.env.REACT_APP_API_URL}/showroom-users/choose-plan`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest"
                }
            })

            if (response.data) {
                let resData = response.data
                // if (resData.status) {
                //     setLoader(false)
                //     popClose()
                //     reset()
                // }
                setLoader(false)
                popClose()
                reset()

            }
        }
        catch (error) {
            if (error.response && error.response.data) {
                console.log('Error : ', error.response.data.msg)
            }
            setLoader(false)
        }



    }

    return (
        <>
            <Modal
                show={open}
                onHide={() => { reset(); popClose() }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className='requestFormModal planSelectedModal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter text-center">
                        Package Plan Request
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <Row>
                            <Col>
                                <Form className='requestForm' onSubmit={handleSubmit(handleFormSubmit)}>
                                    <div className='planName startDiv mb-3'>
                                        <span>{base}</span> &nbsp; : &nbsp;<span><b> {plan}</b></span>
                                    </div>
                                    <div className="requestFormInput_container">
                                        <Form.Group className="requestFormInput mb-3" controlId="formBasicEmail">
                                            <Form.Label>Name :</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Name"
                                                {...register('name', { required: 'Name is required' })}
                                            />
                                            {errors.name && <p className='formError_text'>{errors.name.message}</p>}
                                        </Form.Group>
                                        <Form.Group className="requestFormInput mb-3" controlId="formBasicEmail">
                                            <Form.Label>Company Name :</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Company Name"
                                                {...register('companyName', { required: 'Company Name is required' })}
                                            />
                                            {errors.companyName && <p className='formError_text'>{errors.companyName.message}</p>}
                                        </Form.Group>
                                        <Form.Group className="requestFormInput mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email :</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email"
                                                {...register('email', { required: 'Email is required', validate: (value) => { if (!/^\S+@\S+\.\S+$/.test(value)) { return 'Invalid email address'; } }, })}
                                            />
                                            {errors.email && <p className='formError_text'>{errors.email.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="requestFormInput mb-3" controlId="formBasicPassword">
                                            <Form.Label>Phone :</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Phone Number"
                                                {...register('phone', { required: 'Phone number is required', minLength: { value: 7, message: 'Phone number must be at least 7 digits', }, maxLength: { value: 15, message: 'Phone number cannot exceed 15 digits', }, pattern: { value: /^\d{7,15}$/, message: 'Invalid phone number', }, })}
                                            />
                                            {errors.phone && <p className='formError_text'>{errors.phone.message}</p>}
                                        </Form.Group>
                                    </div>

                                    <div className='endDiv mt-3'>
                                        <button type='button' className="secondaryBtn mx-3" style={{ minWidth: '150px' }} onClick={() => { reset(); popClose() }}>Cancel</button>
                                        <button className="primaryBtn" style={{ minWidth: '150px', backgroundColor: '#ff0303' }} type="submit">
                                            {loader ? <Spinner /> : 'Submit'}
                                        </button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SelectPlanForm