
import './App.css';
import HomePage from './Homepage/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Topbar from './Topbar/Topbar';
import Footer from './Footer/Footer';
import { useState } from 'react';

import "slick-carousel/slick/slick.css";
 import "slick-carousel/slick/slick-theme.css";

function App() {

  const [loader, setLoader] = useState(false)


  return (
    <>
      <div className='wrapper'>
        {loader && <Topbar />}
        <HomePage loader={loader} setLoader={setLoader}/>
        {loader && <Footer />}
        
        <div style={{height:'100vh', backgroundColor:'#f6f6f6', display: loader ? 'none' : 'flex' }} className='w_h_100 centerDiv'>
          <div className='suspense_loader'></div>
        </div>
      </div>
    </>
  );
}

export default App;
